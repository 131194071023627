import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

declare var Particles: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('showCard', [
      state(
        'society-visible',
        style({
          opacity: 1,
          transform: 'translate(0px, 0px) rotate(0deg) scale(1)',
          'box-shadow': 'rgba(237, 220, 102, 0.6) 2px 2px 10px',
        })
      ),
      state(
        'technology-visible',
        style({
          opacity: 1,
          transform: 'translate(0px, 0px) rotate(0deg) scale(1)',
          'box-shadow': 'rgba(120, 186, 155, 0.6) 2px 2px 10px',
        })
      ),
      state(
        'life-visible',
        style({
          opacity: 1,
          transform: 'translate(0px, 0px) rotate(0deg) scale(1)',
          'box-shadow': 'rgba(57, 131, 155, 0.6) 2px 2px 10px',
        })
      ),
      state(
        'feeling-visible',
        style({
          opacity: 1,
          transform: 'translate(0px, 0px) rotate(0deg) scale(1)',
          'box-shadow': 'rgba(176, 33, 108, 0.6) 2px 2px 10px',
        })
      ),
      state(
        'society',
        style({
          opacity: 0,
          transform: 'translate(-5px, 200px) rotate(90deg) scale(2)',
          'box-shadow': 'rgba(0, 0, 0, 0.6) 2px 2px 40px',
        })
      ),
      state(
        'technology',
        style({
          opacity: 0,
          transform: 'translate(25px, 300px) rotate(-50deg) scale(2)',
          'box-shadow': 'rgba(0, 0, 0, 0.6) 2px 2px 40px',
        })
      ),
      state(
        'life',
        style({
          opacity: 0,
          transform: 'translate(25px, 300px) rotate(-180deg) scale(2)',
          'box-shadow': 'rgba(0, 0, 0, 0.6) 2px 2px 40px',
        })
      ),
      state(
        'feeling',
        style({
          opacity: 0,
          transform: 'translate(25px, 300px) rotate(22deg) scale(2)',
          'box-shadow': 'rgba(0, 0, 0, 0.6) 2px 2px 40px',
        })
      ),
      transition('feeling => feeling-visible', [
        animate('0.75s cubic-bezier(0.35, 0, 0.25, 1)'),
      ]),
      transition('technology => technology-visible', [
        animate('0.75s cubic-bezier(0.35, 0, 0.25, 1)'),
      ]),
      transition('society => society-visible', [
        animate('0.75s cubic-bezier(0.35, 0, 0.25, 1)'),
      ]),
      transition('life => life-visible', [
        animate('0.75s cubic-bezier(0.35, 0, 0.25, 1)'),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  public state = 'init';

  public stackSize = [18, 18, 11, 11];

  public cards = [
    {
      url: 0,
      id: 'society',
      visible: 'society',
      side: 'default',
      randomized: false,
      description: 'Gesellschaft',
    },
    {
      url: 0,
      id: 'technology',
      visible: 'technology',
      side: 'default',
      randomized: false,
      description: 'Technologie',
    },
    {
      url: 0,
      id: 'life',
      visible: 'life',
      side: 'default',
      randomized: false,
      description: 'Lebensbereich',
    },
    {
      url: 0,
      id: 'feeling',
      visible: 'feeling',
      side: 'default',
      randomized: false,
      description: 'Gefühl',
    },
  ];

  constructor(private _changeDetectorRef: ChangeDetectorRef) {
    try {
      const particles = Particles.init({
        selector: '.background',
        color: '#ffffff',
      });
    } catch (e) {
      console.error(e);
    }

    this.ShowCards(0);

    console.log('V 1.1');
  }

  public Shuffle() {
    if (this.state !== 'shuffle') {
      this.state = 'shuffle';
      this.RotateCards(0);
    }
  }

  private RotateCards(n) {
    this.cards[n].side = 'back';
    this._changeDetectorRef.markForCheck();

    this.RandomizeSingleCard(n, 1);

    if (n + 1 < this.cards.length) {
      setTimeout(() => {
        this.RotateCards(n + 1);
      }, 450);
    }
  }

  private RandomizeSingleCard(stack: number, timeout: number) {
    this.cards[stack].url = Math.floor(Math.random() * this.stackSize[stack]);
    timeout = timeout * 1.1;
    this._changeDetectorRef.markForCheck();
    if (timeout < 500) {
      setTimeout(() => {
        this.RandomizeSingleCard(stack, timeout);
      }, timeout);
    } else {
      this.cards[stack].randomized = true;
      if (stack === this.cards.length - 1) {
        this.state = 'shuffled';
      }
    }
  }

  private ShowCards(n: number) {
    setTimeout(() => {
      this.cards[n].visible = this.cards[n].id + '-visible';
      this._changeDetectorRef.markForCheck();
      if (n + 1 < this.cards.length) {
        this.ShowCards(n + 1);
      }
    }, 450);
  }

  public GetArray(n) {
    return [...Array(n).keys()];
  }
}
