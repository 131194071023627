<div class="grid-wrapper">
  <div class="row">
    <div class="spacer spacer--8"></div>
    <div
      class="col-xsmall-12 col-small-10 col-medium-8 col-small-offset-1 col-medium-offset-2"
    >
      <h1>
        <span class="small">
          DAS SPECULATIVE DESIGN KARTENSPIEL VON
          <a href="https://liechtenecker.at" target="_blank"
            >LIECHTENECKER UX Design Studio</a
          >
        </span>
        What the Future
      </h1>

      <p>
        Welche aufstrebenden Technologien werden sich durchsetzen? Wie können
        wir sie sinnvoll einsetzen? Sind neue Technologien mit Nachhaltigkeit
        und Menschlichkeit vereinbar? Wir haben das What The Future Kartenspiel
        entwickelt, damit ihr euch diesen Fragestellungen öfter und besser
        widmen könnt.
      </p>

      <div class="spacer spacer--4"></div>

      <div class="button button-square" (click)="Shuffle()">
        <div class="square animate"></div>
        <div class="text">Karten mischen</div>
      </div>
    </div>
  </div>
</div>
<div class="grid-wrapper-large grid-wrapper-large-no-padding">
  <div class="row">
    <div class="col-xsmall-12">
      <div class="cards">
        <ng-container *ngFor="let card of cards">
          <div class="cards__card-wrapper">
            <div class="cards__card-place"></div>
            <div class="content" [@showCard]="card.visible">
              <div
                class="cards__description"
                [ngClass]="{ 'cards__description--visible': card.randomized }"
              >
                <p>{{ card.description }}</p>
              </div>
              <div
                class="cards__card cards__card--{{ card.id }}"
                [ngClass]="{ rotated: card.side == 'back' }"
              >
                <img [src]="'assets/cards/' + card.id + '/cover.jpg'" />
              </div>
              <div
                class="cards__card cards__card--back cards__card--{{ card.id }}"
                [ngClass]="{ rotated: card.side == 'back' }"
              >
                <img
                  [src]="
                    'assets/cards/' + card.id + '/card-' + card.url + '.jpg'
                  "
                />
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="spacer spacer--8"></div>
  <p>
    Du möchtest mehr zum Kartenspiel erfahren und das Spiel bestellen?
    <a href="http://whatthefuture.at" target="_blank">
      Mehr Informationen!
    </a>
  </p>

  <div class="spacer spacer--20"></div>

  <p>
    &copy; Liechtenecker,
    <a href="https://liechtenecker.at/impressum">Impressum</a>
  </p>
</div>
<div style="display: none;">
  <ng-container *ngFor="let stack of [0, 1, 2, 3]">
    <ng-container
      *ngFor="let card of GetArray(stackSize[stack]); let i = index"
    >
      <img [src]="'assets/cards/' + cards[stack].id + '/card-' + i + '.jpg'" />
    </ng-container>
  </ng-container>
</div>
